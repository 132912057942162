<template>
  <div style="margin-top: 200px;">
    <a href="https://oss.baigongbao.com/2023/12/15/wbRQn4QcTN.docx" download="wbRQn4QcTN.docx" target="_blank">打开链接</a>
  </div>

</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>